import React from 'react'
import PageRenderer from '@/pages.impl/PageRenderer'
import { LandingPage } from '@/types'
import { BlockArray } from '@blocks'
import { serverSideProps } from '@/server/utils/serverSideProps'
import { Pages } from '@/server/Pages'
import preloadBlocksData from '@/server/utils/preloadBlocksData'

interface LandingPageProps {
  page: LandingPage
  blocks: BlockArray
}

export const getServerSideProps = serverSideProps<LandingPageProps>(async ({ school, isEditor, apollo }) => {
  const { page, blocks } = await Pages.load(school.id, 'Landing', isEditor)

  await preloadBlocksData(blocks, apollo);

  return {
    props: {
      page: page as LandingPage,
      blocks
    },
  }
})

const IndexPage: React.FC<LandingPageProps> = ({ page, blocks }) => {
  return <PageRenderer page={page} blocks={blocks}/>
}

export default IndexPage
